import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/inner"
import SEO from "../components/seo"
import CenterInnerContent from "../components/modules/center-inner-content"
import { getImage } from "../../util/functions"

export default ({ data }) => {
  //highlight-line
  const page = data.wpPage
  const robots = {
    name: `robots`,
    content: `${page.seo.metaRobotsNoindex}, ${page.seo.metaRobotsNofollow}`,
  }
  const meta = [robots]
  const seo = page.seo

  const addToMeta = (name, content) => {
    if (content) meta.push({ name: name, content: content })
  }
  addToMeta("og:site_name", seo.opengraphSiteName)
  addToMeta(
    "og:image",
    seo.opengraphImage?.localFile?.publicURL
      ? getImage(seo.opengraphImage.localFile.publicURL, true, false)
      : null
  )
  addToMeta("article:publisher", seo.opengraphPublisher)
  addToMeta("article:modified_time", seo.opengraphModifiedTime)
  addToMeta("article:published_time", seo.opengraphPublishedTime)
  addToMeta("og:type", seo.opengraphType)
  return (
    <Layout>
      <SEO
        title={page.seo.title}
        description={page.seo.metaDesc}
        meta={meta}
        schema={page.seo.schema?.raw}
      />
      <CenterInnerContent
        hideCTA
        sidebarWidth={450}
        hideMenu
        showWhitePapers
        spacing
        bgText="MARRONE"
      >
        {page.content}
      </CenterInnerContent>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    wp {
      awards {
        aw {
          awTitle
          awReviews {
            author
            body
            excerpt
          }
        }
      }
      nextlevel {
        themeInfo {
          themeName
        }
      }
    }
    wpPage(id: { eq: $id }) {
      content
      seo {
        ...SEO
        ...PostSEO
      }
      id
      uri
      template {
        templateName
      }
      title
    }
  }
`
